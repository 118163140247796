export const lightTheme = {
  body: "#f5f5f5",
  text: "#212529",
};

export const darkTheme = {
  body: `linear-gradient(
    159deg,
    rgba(37, 37, 50, 0.98) 0%,
    rgba(35, 35, 45, 0.98) 100%
  )`,
  text: "#fafafc",
};
